export default {
  //随机生成本地id暂为设备编号用
  generateRandomId(length) {
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var id = '';
    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * chars.length);
      id += chars.charAt(randomIndex);
    }
    return id;
  },

  getEquipment() {
    //判断数组中是否包含某字符串
    Array.prototype.contains = function (needle) {
      for (i in this) {
        if (this[i] || this[i].indexOf(needle) > 0) return i;
      }
      return -1;
    };
    let banbenhao;
    var device_type = navigator.userAgent; //获取userAgent信息
    // console.log('device_type=>',device_type);
    var md = new MobileDetect(device_type); //初始化mobile-detect
    var os = md.os(); //获取系统
    // console.log('os==>',os)
    var model = '';
    if (os == 'iOS') {
      //ios系统的处理
      os = +md.version('iPhone');
      model = md.mobile();
      banbenhao = this.iosVer();
    } else if (os == 'AndroidOS') {
      //Android系统的处理
      os = md.os() + md.version('Android');
      var sss = device_type.split(';');
      var i = sss.contains('Build/');
      banbenhao = this.AndVer();

      if (i > -1) {
        model = sss[i].substring(0, sss[i].indexOf('Build/'));
      }
    } else {
      banbenhao = this.windowsVer();
      if (!banbenhao) {
        banbenhao = this.getMacersion();
      }

      os = this.getOS();
      model = this.getBrowser();
    }
    // console.log('dev==>',model + '打印系统版本和手机型号=>' + os,+'系统版本号==>',banbenhao)
    return { os: os, dev: model, os_version: banbenhao };
  },
  // 系统区分
  getOS() {
    var u = navigator.userAgent;
    if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
      return 'Windows';
    } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
      return 'MacOS';
    } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
      return 'IOS';
    } else if (u.match(/android/i)) {
      return 'Android';
    } else if (u.match(/Ubuntu/i)) {
      return 'Ubuntu';
    } else {
      return 'Other';
    }
  },
  /* 版本信息获取 */
  // 各主流浏览器
  getBrowser() {
    var u = navigator.userAgent;
    var bws = [
      {
        name: 'sgssapp',
        it: /sogousearch/i.test(u),
      },
      {
        name: 'wechat',
        it: /MicroMessenger/i.test(u),
      },
      {
        name: 'weibo',
        it: !!u.match(/Weibo/i),
      },
      {
        name: 'UC',
        it: !!u.match(/UCBrowser/i) || u.indexOf(' UBrowser') > -1,
      },
      {
        name: 'sogou',
        it: u.indexOf('MetaSr') > -1 || u.indexOf('Sogou') > -1,
      },
      {
        name: 'xiaomi',
        it: u.indexOf('MiuiBrowser') > -1,
      },
      {
        name: 'Baidu',
        it: u.indexOf('Baidu') > -1 || u.indexOf('BIDUBrowser') > -1,
      },
      {
        name: '360',
        it: u.indexOf('360EE') > -1 || u.indexOf('360SE') > -1,
      },
      {
        name: '2345',
        it: u.indexOf('2345Explorer') > -1,
      },
      {
        name: 'edge',
        it: u.indexOf('Edge') > -1,
      },
      {
        name: 'ie11',
        it: u.indexOf('Trident') > -1 && u.indexOf('rv:11.0') > -1,
      },
      {
        name: 'ie',
        it: u.indexOf('compatible') > -1 && u.indexOf('MSIE') > -1,
      },
      {
        name: 'Firefox',
        it: u.indexOf('Firefox') > -1,
      },
      {
        name: 'Safari',
        it: u.indexOf('Safari') > -1 && u.indexOf('Chrome') === -1,
      },
      {
        name: 'MQQBrowser',
        it: u.indexOf('MQQBrowser') > -1 && u.indexOf(' QQ') === -1,
      },
      {
        name: 'QQ',
        it: u.indexOf('QQ') > -1,
      },
      {
        name: 'Chrome',
        it: u.indexOf('Chrome') > -1 || u.indexOf('CriOS') > -1,
      },
      {
        name: 'Opera',
        it: u.indexOf('Opera') > -1 || u.indexOf('OPR') > -1,
      },
    ];
    for (var i = 0; i < bws.length; i++) {
      if (bws[i].it) {
        return bws[i].name;
      }
    }

    return 'other';
  },
  // 获取Mac版本号
  getMacersion() {
    var ua = navigator.userAgent.toLowerCase();
    var version = null;
    var reg = /x [\d._]+/gi;
    var v_info = ua.match(reg);
    version = (v_info + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.'); //得到版本号9.3.2或者9.0
    // version = parseInt(version.split('.')[0]); // 得到版本号第一位
    return version;
  },

  iosVer() {
    let userAgent = navigator.userAgent;
    let iOSRegex = /iPhone OS (\d+_?\d+_?\d+)?/;
    let resultiOS = iOSRegex.exec(userAgent);
    if (resultiOS) {
      let iosVersion = resultiOS[1].replace(/_/g, '.');
      console.log('iOS 版本号：' + iosVersion);
      return iosVersion;
    }
  },
  AndVer() {
    let userAgent = navigator.userAgent;
    // 获取 Android 版本号
    let androidRegex = /Android (\d+(\.\d+)?)/;
    let resultAndroid = androidRegex.exec(userAgent);
    if (resultAndroid) {
      let androidVersion = resultAndroid[1];
      console.log('Android 版本号：' + androidVersion);
      return androidVersion;
    }
  },
  windowsVer() {
    let userAgent = navigator.userAgent;
    let windowsRegex = /Windows NT (\d+\.\d+)/;
    let resultWindows = windowsRegex.exec(userAgent);
    if (resultWindows) {
      let windowsVersion = resultWindows[1];
      console.log('Windows 版本号：' + windowsVersion);
      return windowsVersion;
    }
  },
};
