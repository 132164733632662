import Vue from 'vue';
import VueI18n from 'vue-i18n';

// 语言包
import en from '../assets/lang/en.js';
import cn from '../assets/lang/cn.js';
import hk from '../assets/lang/hk.js';
import jp from '../assets/lang/jp.js';
import ko from '../assets/lang/ko.js';
import de from '../assets/lang/de.js';
import fr from '../assets/lang/fr.js';
import it from '../assets/lang/it.js';
import es from '../assets/lang/es.js';
import ru from '../assets/lang/ru.js';
import hi from '../assets/lang/hi.js';

import enUS from 'view-design/dist/locale/en-US';
import zhCN from 'view-design/dist/locale/zh-CN';
import zhTW from 'view-design/dist/locale/zh-TW';
import jaJP from 'view-design/dist/locale/ja-JP';
import koKR from 'view-design/dist/locale/ko-KR';
import deDE from 'view-design/dist/locale/de-DE';
import frFR from 'view-design/dist/locale/fr-FR';
import itIT from 'view-design/dist/locale/it-IT';
import esES from 'view-design/dist/locale/es-ES';
import ruRU from 'view-design/dist/locale/ru-RU';
import hiIN from 'view-design/dist/locale/hi-IN';

Vue.use(VueI18n);

export default new VueI18n({
  // locale: systemLang, // 会在 vuex 里写入
  messages: {
    zh_CN: Object.assign(zhCN, cn),
    en_US: Object.assign(enUS, en),
    zh_HK: Object.assign(zhTW, hk),
    ja_JP: Object.assign(jaJP, jp),
    ko_KR: Object.assign(koKR, ko),
    de_DE: Object.assign(deDE, de),
    fr_FR: Object.assign(frFR, fr),
    it_IT: Object.assign(itIT, it),
    es_ES: Object.assign(esES, es),
    ru_RU: Object.assign(ruRU, ru),
    hi_IN: Object.assign(hiIN, hi),
  },
  silentTranslationWarn: true,
});
