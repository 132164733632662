import Vue from 'vue';
import router from './config/router.js';
import store from './config/store.js';
import i18n from './config/i18n';

import App from './App.vue';
import Api from './config/api';
import moment from 'moment'; // 时间格式化
import momentTimezone from 'moment-timezone'; // 处理时区

import './assets/js/util.js'; // 内涵对字符串和数字原型链的修改
import tool from './utils/equipment'; // 判断和获取设备信息相关

import vueResource from 'vue-resource'; // Vue的一个http插件，可以通过使用全局Vue.http或者Vue实例this.$http来引用http服务
import VueClipboard from 'vue-clipboard2'; // 调用剪切板的一个插件，可以实现复制内容到剪切板
import VueQriously from 'vue-qriously'; // 二维码快速生成图片

// ViewUI相关
import ViewUI, { Modal } from 'view-design';
import 'view-design/dist/styles/iview.css';
import './assets/scss/app.scss'; // 内有覆盖 iview.css 的内容，需要写在其下面

// Vant
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';

import resize from './assets/js/resize'; // 检测窗口大小发生变化的函数

import microApp from '@micro-zoe/micro-app'; // 微前端
microApp.start();

Vue.use(vueResource);
Vue.use(VueClipboard);
Vue.use(VueQriously);

Vue.use(ViewUI, { i18n: (key, value) => i18n.t(key, value) });
Vue.use(Vant);
Vue.use(Lazyload, {
  lazyComponent: true,
});

Vue.directive('resize', resize);

Vue.prototype.rootHost = process.env.VUE_APP_ROOT_URL;
Vue.prototype.host = process.env.VUE_APP_BASE_URL;

// 全局封装人机验证
Vue.prototype.$initGeetest4 = () => {
  return new Promise((res, rej) => {
    let captchaW = document.querySelector('.captchaW');
    captchaW.style.display = 'flex';
    initGeetest4(
      {
        captchaId: 'e61730fa5d35dee4459f56a625c17c48',
      },
      function (captchaObj) {
        // captchaObj 为验证码实例
        let captchaDom = document.getElementById('captchaDom');
        captchaDom.innerHTML = '';
        captchaObj.appendTo(captchaDom); // 调用appendTo将验证码插入到页的某一个元素中，这个元素用户可以自定义
        captchaObj.onSuccess(function () {
          let result = captchaObj.getValidate();
          res(result);
          setTimeout(() => {
            captchaW.style.display = 'none';
          }, 500);
        });
      },
    );
  });
};

Vue.prototype.$bus = new Vue(); // bus总线
Vue.prototype.api = Api;

Vue.http.options.credentials = true;
Vue.http.options.emulateJSON = true;
Vue.http.options.headers = {
  'Content-Type': 'application/json;charset=utf-8',
};

//暂用一个uuid代替设备独立编号
if (!localStorage.getItem('uuid')) {
  localStorage.setItem('uuid', tool.generateRandomId(16));
}
let result = tool.getEquipment();
let UzxOs = (result.os || '').toString(); // 操作系统
let UzxDevice = (result.dev || '').toString(); // 设备
let UzxOsVersion = (result.os_version || '').toString(); // 操作系统版本
let UzxDeviceVersion = localStorage.getItem('uuid'); // 设备编号(必填)
localStorage.setItem('localityDv', result.dev);

Vue.http.interceptors.push((request, next) => {
  /* 以下接口始终不走模拟交易的接口，走正常接口：
      /marke/、/exchange/、/uc/loginout、/uc/member/smlt-acc
   */
  // if (
  //   request.url.includes('/market/') ||
  //   request.url.includes('/exchange/') ||
  //   request.url.includes('/uc/loginout') ||
  //   request.url.includes('/uc/member/smlt-acc')
  // ) {
  //   request.url = request.url.replace(mockHost, host); // 替换接口地址前缀
  // }

  // 模拟交易的 token 不一样
  // if (request.url.includes(mockHost)) {
  //   request.headers.set('access-auth-token', localStorage.getItem('TOKEN_MOCK'));
  // } else {
  // 登录成功后将后台返回的TOKEN在本地存下来,每次请求从sessionStorage中拿到存储的TOKEN值
  request.headers.set('access-auth-token', localStorage.getItem('TOKEN'));
  // }

  request.headers.set('lang', store.getters.lang);

  request.headers.set('Uzx-Os', UzxOs);
  request.headers.set('Uzx-Device', UzxDevice);
  request.headers.set('Uzx-Os-Version', UzxOsVersion);
  request.headers.set('Uzx-Device-Version', UzxDeviceVersion);

  next((response) => {
    // 只处理非模拟交易时的登录失效的跳转
    // if (!request.url.includes(mockHost)) {
    if (response.data.code === 4000 || response.data.code === 3000) {
      store.commit('setMember', null);
      const toLogin = () => {
        // 如果已经在登录页了则不再跳转
        if (!router.history.current.path.includes('/login')) {
          router.push(`/login?prepath=${router.history.current.fullPath}`);
        }
      };
      Modal.confirm({
        width: 500,
        title: i18n.t('uc.login.loginFailure'), // 登录失效
        content: i18n.t('uc.login.loginFailureDoc'), // 登录状态已失效，请重新登录。
        onOk: toLogin,
        onCancel: toLogin,
      });
      return false;
    }
    // }
    return response;
  });
});

Vue.config.productionTip = false;

Vue.filter('timeFormat', function (tick) {
  return moment(tick).format('HH:mm:ss');
});

Vue.filter('dateFormat', function (tick) {
  return moment(tick).format('YYYY-MM-DD HH:mm:ss');
});

Vue.filter('timeFormatHHmm', function (tick) {
  return moment(tick).format('YYYY-MM-DD HH:mm');
});

Vue.filter('toFixed', function (number, scale) {
  return new Number(number).toFixed(scale);
});

Vue.filter('toPercent', function (point) {
  var str = Number(point * 100).toFixed(1);
  str += '%';
  return str;
});

function toFloor(number, scale = 8) {
  if (new Number(number) == 0) {
    return 0;
  }
  var __str = number + '';
  if (__str.indexOf('e') > -1 || __str.indexOf('E') > -1) {
    var __num = new Number(number).toFixed(scale + 1),
      __str = __num + '';
    return __str.substring(0, __str.length - 1);
  } else if (__str.indexOf('.') > -1) {
    if (scale == 0) {
      return __str.substring(0, __str.indexOf('.'));
    }
    return __str.substring(0, __str.indexOf('.') + scale + 1);
  } else {
    return __str;
  }
}

Vue.filter('toFloor', (number, scale) => {
  return toFloor(number, scale);
});
Vue.prototype.toFloor = toFloor;

Vue.prototype.getTimezone4K = function () {
  // 设备的时区
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
Vue.prototype.getLang4K = function () {
  var curlang = this.$store.getters.lang;
  if (curlang == 'en_US') {
    return 'en';
  }
  if (curlang == 'ja_JP') {
    return 'ja';
  }
  if (curlang == 'ko_KR') {
    return 'ko';
  }
  if (curlang == 'de_DE') {
    return 'de_DE';
  }
  if (curlang == 'fr_FR') {
    return 'fr';
  }
  if (curlang == 'it_IT') {
    return 'it';
  }
  if (curlang == 'es_ES') {
    return 'es';
  }
  if (curlang == 'zh_HK') {
    return 'zh_TW';
  }
  if (curlang == 'zh_CN') {
    return 'zh';
  }
  if (curlang == 'hi_IN') {
    return 'hi';
  }
  if (curlang == 'ru_RU') {
    return 'ru';
  }
  return curlang;
};

Vue.prototype.timeFormat = function (tick) {
  return momentTimezone(tick).tz(this.getTimezone4K()).format('HH:mm:ss');
};
Vue.prototype.dateFormat = function (tick) {
  return momentTimezone(tick).tz(this.getTimezone4K()).format('YYYY-MM-DD HH:mm:ss');
};
Vue.prototype.briefDateFormat = function (tick) {
  return momentTimezone(tick).tz(this.getTimezone4K()).format('YYYY-MM-DD');
};
Vue.prototype.dateFormatSS = function (tick) {
  return momentTimezone(tick).tz(this.getTimezone4K()).format('ss');
};
Vue.prototype.dateFormatHM = function (tick) {
  return momentTimezone(tick).tz(this.getTimezone4K()).format('YYYY-MM-DD HH:mm');
};
Vue.prototype.dateFormatFromString = function (tick) {
  var timestamp = momentTimezone(tick).tz('Asia/Shanghai').valueOf();
  return momentTimezone(timestamp).tz(this.getTimezone4K()).format('YYYY-MM-DD HH:mm:ss');
};

function setRem() {
  const html = document.documentElement;
  const width = html.clientWidth;
  html.style.fontSize = width / 10 + 'px';
}

// 页面加载时调用一次
setRem();

// 监听窗口大小变化时重新计算rem值
window.addEventListener('resize', setRem);

new Vue({
  el: '#app_W',
  router,
  i18n,
  store,
  template: '<App/>',
  components: { App },
});
